import 'systemjs/dist/system';

const originalResolve = System.constructor.prototype.resolve;
System.constructor.prototype.resolve = function resolve(id: string) {
    try {
        // eslint-disable-next-line prefer-rest-params
        return originalResolve.apply(this, arguments);
    } catch (error) {
        if (id === 'jquery') {
            return (window as unknown as { jQuery: unknown }).jQuery;
        }
        throw error;
    }
};
