/**
 * Returns a function that returns a memo when calling in parallel, and refetches data when called in sequence.
 */
export function noParallel<T>(fn: () => Promise<T>): () => Promise<T> {
    let memo: Promise<T> | undefined;

    return async () => {
        if (memo) {
            return memo;
        }

        memo = fn().finally(() => (memo = undefined));
        return memo;
    };
}
