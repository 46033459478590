"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getConfigCatUser = void 0;
function getConfigCatUser(userData) {
    const custom = {
        type: userData.type,
        audience: userData.audience,
        verifiedUser: userData.isLoggedIn && userData.verifiedUser ? 'true' : 'false',
    };
    if (userData.devID) {
        custom.devID = userData.devID;
        // For backward compatibility
        custom.target = userData.devID;
    }
    return {
        identifier: userData.identifier,
        custom,
    };
}
exports.getConfigCatUser = getConfigCatUser;
