import 'systemjs/dist/system';
import 'systemjs/dist/extras/amd';
import { LifeCycles } from 'single-spa';
import { RootConfig } from '@combinativ/config';
import { Logger } from './logger';
import { Platform } from './platform';

/**
 * Module to load apps
 */
export interface AppLoader {
    getOwnerFromAppName(appName: string): null | string;
    loadApp(config: { name: string }): Promise<LifeCycles>;
}

function getAppSpaCallbacks(appBundle: any, props: Record<string, any>): LifeCycles {
    const mainSpa = appBundle.mainSpa || (appBundle.default && appBundle.default.mainSpa);

    if (mainSpa !== undefined) {
        return mainSpa(props);
    }
    return appBundle;
}

function fetchStyle(url: string) {
    return new Promise<void>((resolve) => {
        const link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.onload = () => {
            resolve();
        };
        link.href = url;

        document.head.appendChild(link);
    });
}

export function appLoaderFactory({ rootConfig, logger, platform }: { rootConfig: RootConfig; logger: Logger; platform: Platform }): AppLoader {
    const { apps } = rootConfig;
    return {
        getOwnerFromAppName(appName: string) {
            if (!apps[appName]?.owner) {
                return null;
            }
            return apps[appName].owner as string;
        },
        loadApp({ name: appName }) {
            const appConfig = apps[appName];
            if (!appConfig) {
                throw new Error(`Invalid MFE name: ${appName}`);
            }
            const { spaBundle, jsBundle, cssBundle } = appConfig;

            // TODO: Remove when MFE's update to jsBundle
            const bundleUrl = spaBundle || jsBundle;

            const waitTill: Promise<any>[] = [System.import(bundleUrl as string)];

            if (cssBundle !== undefined) {
                waitTill.push(fetchStyle(cssBundle));
            }

            return Promise.all(waitTill).then(([bundle]) => {
                const spaCallbacks = getAppSpaCallbacks(bundle, {
                    platform: String(platform),
                    ...appConfig.props,
                });
                logger.debug(`Loaded app: ${appName}`, { appName, bundle, spaCallbacks });
                return spaCallbacks;
            });
        },
    };
}
