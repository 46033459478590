import { RendererServices } from './services';

type OnReadyCallback = (services: RendererServices) => void;

/**
 * Functions to call when the renderer becomes ready or services if it's already in the ready state.
 */
let state: { listeners: OnReadyCallback[]; services?: never } | { listeners?: never; services: RendererServices } = { listeners: [] };

/**
 * Call a function when the renderer becomes ready. Call it immediately if it's in ready state already.
 * @param listener Function to call when it becomes ready
 */
export function onReady(listener: OnReadyCallback) {
    if (state.listeners) {
        state.listeners.push(listener);
    } else {
        listener(state.services);
    }
}

export function triggerReadyState(services: RendererServices) {
    if (!state.listeners) {
        return;
    }
    const { listeners } = state;
    state = { services };
    listeners.forEach((listener) => {
        listener(services);
    });
}
