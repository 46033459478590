import { IdentificationInfo, LogLevel } from '@combinativ/client-api';
import { datadogLogs, StatusType, Logger as DatadogLogger, HandlerType } from '@datadog/browser-logs';

let debugLogsEnabled = false;
try {
    if (localStorage.getItem('combinativ-debug-logs')) {
        debugLogsEnabled = true;
    }
} catch {
    // ignore errors
}

export interface Logger {
    rawLog(level: LogLevel, identificationInfo: IdentificationInfo, message: string, data?: Record<string, any>): void;
    debug(message: string, data?: Record<string, any>): void;
    info(message: string, data?: Record<string, any>): void;
    warn(message: string, data?: Record<string, any>): void;
    error(message: string, data?: Record<string, any>): void;
}

const colors: Map<LogLevel, string> = new Map<LogLevel, string>();

colors.set(LogLevel.DEBUG, 'background-color: magenta; color: white;');
colors.set(LogLevel.INFO, 'background-color: blue; color: white;');
colors.set(LogLevel.WARN, 'background-color: orange; color: white;');
colors.set(LogLevel.ERROR, 'background-color: red; color: white;');
const defaultColor = 'background-color: transparent; color: initial;';

export function getDatadogStatusType(level: LogLevel): StatusType {
    switch (level) {
        case LogLevel.DEBUG:
            return StatusType.debug;
        case LogLevel.INFO:
            return StatusType.info;
        case LogLevel.ERROR:
            return StatusType.error;
        case LogLevel.WARN:
            return StatusType.warn;
        default:
            throw Error(`Cannot retrieve Datadog status type for log level (${level}`);
    }
}

type LogTransport = {
    log: (level: LogLevel, identificationInfo: IdentificationInfo, message: string, data: Record<string, any>) => void;
};

function createDatadogTransport(): LogTransport {
    const clientToken = process.env.DD_CLIENT_TOKEN;
    if (!clientToken) {
        // eslint-disable-next-line no-console
        console.warn('Client token missing');
        return {
            log() {},
        };
    }
    datadogLogs.init({
        clientToken,
        site: 'datadoghq.com',
        sampleRate: 100,
        service: 'combinativ-client',
        env: 'prod',
        forwardErrorsToLogs: true,
    });
    const minimumLevel: StatusType = debugLogsEnabled ? StatusType.debug : StatusType.info;
    const handler: HandlerType | HandlerType[] = debugLogsEnabled ? ['http', 'console'] : 'http';
    datadogLogs.logger.setLevel(minimumLevel);
    datadogLogs.logger.setHandler(handler);

    const loggers: Record<string, DatadogLogger> = {};
    function getLogger({ name, squad }: IdentificationInfo) {
        if (!loggers[name]) {
            loggers[name] = datadogLogs.createLogger(name, {
                context: { service: name, squad },
                level: minimumLevel,
                handler,
            });
        }
        return loggers[name];
    }

    return {
        log(level, identificationInfo, message, data) {
            getLogger(identificationInfo).log(message, data, getDatadogStatusType(level));
        },
    };
}

function createConsoleTransport(): LogTransport {
    return {
        log(level, identificationInfo, message, data) {
            const color = colors.get(level) || defaultColor;
            // eslint-disable-next-line no-console
            console.log('%c %s %c [%s] %s', `font-weight: bold; ${color}`, LogLevel[level], `font-weight: normal; ${defaultColor}`, identificationInfo.name, message, data);
        },
    };
}

export function loggerFactory(combinativIdInfo: IdentificationInfo): Logger {
    const env = process.env.NODE_ENV || 'production';
    const transport = env === 'production' ? createDatadogTransport() : createConsoleTransport();

    return {
        rawLog: transport.log,
        debug(message: string, data: Record<string, any> = {}) {
            transport.log(LogLevel.DEBUG, combinativIdInfo, message, data);
        },
        info(message: string, data: Record<string, any> = {}) {
            transport.log(LogLevel.INFO, combinativIdInfo, message, data);
        },
        warn(message: string, data: Record<string, any> = {}) {
            transport.log(LogLevel.WARN, combinativIdInfo, message, data);
        },
        error(message: string, data: Record<string, any> = {}) {
            transport.log(LogLevel.ERROR, combinativIdInfo, message, data);
        },
    };
}
