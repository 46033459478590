/* eslint-disable no-console */
import { bootstrap } from './bootstrap';
import { handleError } from './errors';

console.log('Combinativ.js renderer-client: Start bootstrap');
Promise.resolve(bootstrap())
    .then(() => {
        console.log('Combinativ.js renderer-client: Bootstrap done');
    })
    .catch(handleError);
