import { CombinativClientAPI, HeadTagAttributes, HeadTags, UserLocation } from '@combinativ/client-api';
import { Logger } from './logger';
import { FeatureFlagsService } from './feature-flags';
import { setHeadElement } from './html-head-tags';
import { UserData } from '@combinativ/user-data';

export function clientApiFactory({
    logger,
    featureFlags,
    userLocation,
    userData,
}: {
    logger: Logger;
    featureFlags: FeatureFlagsService | undefined;
    userLocation: UserLocation;
    userData: UserData;
}): CombinativClientAPI {
    return {
        getFeatureFlagValue: featureFlags?.getValue || (() => undefined),
        observeFeatureFlagValue: featureFlags?.observeValue || (() => () => {}),
        getUserLocation: () => userLocation,
        getUserData: () => {
            if (!userData.isLoggedIn) {
                return userData;
            }
            return {
                ...userData,
                /** @deprecated Should not use this property from this data type. */
                get email() {
                    // Passing email as a getter to later identify what apps are still using email from this data type.

                    // TODO: review how to better trace this warning.
                    // logger.warn('Email is still being accessed in this app. Should be no longer used.');
                    return userData.email;
                },
            };
        },
        getUserType: () => {
            if (userData.type === 'NEW') {
                return 'UNKNOWN';
            }
            return userData.type;
        },
        sendLog: (level, identificationInfo, message, data) => {
            logger.rawLog(level, identificationInfo, message, data);
        },
        setTitle: (title: string) => {
            setHeadElement(HeadTags.TITLE, undefined, title);
        },
        setDescription: (description: string) => {
            setHeadElement(HeadTags.META, {
                name: 'description',
                content: description,
            });
        },
        setCanonicalUrl: (href: string) => {
            setHeadElement(HeadTags.LINK, {
                href,
                rel: 'canonical',
            });
        },
        setMeta: (attributes: HeadTagAttributes<HeadTags.META>) => {
            setHeadElement(HeadTags.META, attributes);
        },
    };
}
