import { RootConfig } from '@combinativ/config';
import { handleError } from './errors';

function generateImportMap({ apps }: RootConfig) {
    const deps = {};
    Object.keys(apps).forEach((appName) => {
        const app = apps[appName];

        if (app.dependencies) {
            Object.assign(deps, app.dependencies);
        }
    });
    return { imports: deps };
}

export function setupImportMap(rootConfig: RootConfig) {
    const script = document.createElement('script');
    script.type = 'systemjs-importmap';
    script.text = JSON.stringify(generateImportMap(rootConfig));
    document.head.append(script);
    try {
        // This is just in case SystemJS has already processed the import maps. It would force it to re-process them.
        (System as any).prepareImport(true);
    } catch (error) {
        handleError(error);
    }
}
