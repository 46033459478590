import { AppProps, CustomProps, LifeCycleFn, LifeCycles, Parcel, ParcelConfig, ParcelProps } from 'single-spa';
import { onReady } from './ready';

export type RenderMFEFunction = (mfeName: string, element: HTMLElement | string, props?: Record<string, any>) => void;

function queryDomElement(selector: string) {
    const domElement = document.querySelector(selector);
    if (!domElement) {
        throw new Error(`DOM element not found: ${selector}`);
    }
    if (domElement instanceof HTMLElement) {
        return domElement;
    }
    throw new Error(`"${selector}" is not a valid HTML element.`);
}

function smellsLikeAPromise(promise: any): boolean {
    return promise && typeof promise.then === 'function' && typeof promise.catch === 'function';
}

function flattenFnArray<T = {}>(name: string, appOrParcel: LifeCycles<T>, lifecycle: 'bootstrap' | 'mount'): LifeCycleFn<T> {
    const rawFns = appOrParcel[lifecycle] || [];
    let fns = Array.isArray(rawFns) ? rawFns : [rawFns];
    if (fns.length === 0) {
        fns = [() => Promise.resolve()];
    }

    return function lifeCycleFn(props) {
        return fns.reduce(
            (resultPromise, fn, index) =>
                resultPromise.then(() => {
                    const thisPromise = fn(props);
                    return smellsLikeAPromise(thisPromise)
                        ? thisPromise
                        : Promise.reject(new Error(`Within ${name}, the lifecycle function ${lifecycle} at array index ${index} did not return a promise`));
                }),
            Promise.resolve()
        );
    };
}

export const renderMFE: RenderMFEFunction = (mfeName: string, element: HTMLElement | string, props: Record<string, any> = {}) => {
    onReady(({ appLoader, logger }) => {
        logger.debug('MFE render', { mfeName, element, props });

        function handleAppError(lifecycle: string, error: any) {
            logger.error(`${mfeName} died during ${lifecycle}`, { error, mfeName });
        }

        const domElement = typeof element === 'string' ? queryDomElement(element) : element;
        appLoader
            .loadApp({ name: mfeName })
            .then((app) => {
                const appProps: AppProps & CustomProps = {
                    ...props,
                    name: mfeName,
                    singleSpa: undefined,
                    mountParcel: (parcelConfig: ParcelConfig, customProps: ParcelProps & CustomProps): Parcel => {
                        throw new Error('mountParcel is not available on the Legacy platform');
                    },
                    domElement,
                };
                // mountRootParcel(app, appProps);
                const bootstrap = flattenFnArray(mfeName, app, 'bootstrap');
                const mount = flattenFnArray(mfeName, app, 'mount');

                const bootstrapPromise = Promise.resolve().then(() =>
                    Promise.resolve(bootstrap(appProps)).catch((error) => {
                        handleAppError('bootstrap', error);
                        throw error;
                    })
                );

                const mountPromise = bootstrapPromise.then(() =>
                    Promise.resolve(mount(appProps)).catch((error) => {
                        handleAppError('bootstrap', error);
                        throw error;
                    })
                );

                return mountPromise;
            })
            .catch((error) => {
                handleAppError('loading', error);
            });
    });
};
