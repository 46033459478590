import { HeadElements, HeadTagAttributes, HeadTags } from '@combinativ/client-api';

// Using WeakMap to prevent memory leaks and have the ability to link elements with extra props
const elementsMap = new WeakMap<HeadElements, null>();

function createElement<T extends HeadTags>(tag: T): HeadElements {
    const element = document.createElement(tag);

    elementsMap.set(element, null);
    return element;
}

// Clears all head elements that were created by this lib
function clearHeadElements(): void {
    Array.from(document.head.children).forEach((element) => {
        if (elementsMap.has(element as HeadElements)) {
            element.remove();
        }
    });
}

function addElement(element: HeadElements): HeadElements {
    return document.head.appendChild(element);
}

function removeUniqueExistingElement<T extends HeadTags>(tag: HeadTags, attributes: HeadTagAttributes<T>) {
    if (tag === HeadTags.BASE || tag === HeadTags.TITLE) {
        document.head.querySelector(tag)?.remove();
    } else if (tag === HeadTags.META && (attributes as HeadTagAttributes<HeadTags.META>).name === 'description') {
        document.head.querySelector(`meta[name='description']`)?.remove();
    }
}

export function setHeadElement<T extends HeadTags>(tag: T, attributes: HeadTagAttributes<T> = {} as HeadTagAttributes<T>, body?: string): HeadElements {
    const element = createElement(tag);
    const keys = attributes ? (Object.keys(attributes || ({} as HeadTagAttributes<T>)) as Array<keyof HeadTagAttributes<T>>) : [];

    removeUniqueExistingElement(tag, attributes);

    if (keys.length) {
        keys.forEach((key) => {
            const value = String(attributes![key]);

            if (value) {
                element.setAttribute(key as string, value);
            }
        });
    }

    if (body) {
        element.innerText = body;
    }

    return addElement(element);
}

window.addEventListener('single-spa:before-routing-event', () => {
    clearHeadElements();
});
