import { UserLocation } from '@combinativ/client-api';
import { FeatureFlagConfig, RootConfig } from '@combinativ/config';
import { AppLoader, appLoaderFactory } from './app-loader';
import { clientApiFactory } from './client-api';
import { Logger } from './logger';
import { setupGlobalObject } from './global-object';
import { Platform } from './platform';
import { createConfigCatUpdater, featureFlagsServiceFactory } from './feature-flags';
import { UserData } from '@combinativ/user-data';

export { AppLoader, Logger, Platform };

export type SetupRendererClientOptions = {
    /**
     * The current logger.
     */
    logger: Logger;

    /**
     * The current platform. Either "kiwi" or "legacy".
     */
    platform: Platform;

    /**
     * The resolved root config.
     */
    rootConfig: RootConfig;

    /**
     * The feature flag config containing the flag values.
     */
    featureFlagConfig: FeatureFlagConfig;

    /**
     * The current request emitter's location.
     */
    userLocation: UserLocation;

    /**
     * The current request emitter's user data.
     */
    userData: UserData;

    /**
     * Function to execute during the setup of Combinativ.js.
     */
    bootstrap: (options: { logger: Logger; appLoader: AppLoader }) => void;
};

export async function setupRenderClient({ logger, platform, rootConfig, featureFlagConfig, userLocation, userData, bootstrap }: SetupRendererClientOptions) {
    const ssrFilteredApps: { [appName: string]: any } = {};
    Object.keys(rootConfig.apps).forEach((key) => {
        if (!rootConfig.apps[key].ssr) ssrFilteredApps[key] = rootConfig.apps[key];
    });
    const filteredRootConfig = { ...rootConfig, apps: ssrFilteredApps };

    logger.debug('Start Combinativ.js bootstrap');
    try {
        const appLoader = appLoaderFactory({ logger, rootConfig: filteredRootConfig, platform });
        logger.debug('Retrieved user data', { userData });
        const featureFlags = await featureFlagsServiceFactory({ logger, userData, featureFlagConfig, featureFlagsUpdater: createConfigCatUpdater({ logger }) }).catch((error) => {
            logger.error('Could not instantiate feature flags service', { error });
            return undefined;
        });
        // setupRum({ logger, featureFlags });

        setupGlobalObject({
            clientApi: clientApiFactory({ logger, featureFlags, userLocation, userData }),
        });

        bootstrap({ appLoader, logger });

        logger.debug('Combinativ.js bootstrap finished');
    } catch (error) {
        logger.error('Combinativ.js bootstrap failed', { error });
        throw error;
    }
}
