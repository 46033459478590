import { RootConfig } from '@combinativ/config';

export async function loadRootConfig(distributionChannel: string): Promise<RootConfig> {
    const url = `https://www.manyvids.com/_root-config/${distributionChannel}`;
    const response = await fetch(url, {
        mode: 'cors',
        credentials: 'omit',
    });
    return response.json();
}
