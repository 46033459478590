import { getConfigCatUser } from '@combinativ/user-data';
import { FetchResult, IConfigFetcher, createClientWithAutoPoll, IConfigCatLogger } from 'configcat-common';
import { FeatureFlagConfig } from '@combinativ/config';

import { Logger } from '../logger';
import { FeatureFlagsUpdater, fetchFeatureFlagsFromCacheWithEtag } from './feature-flag';

class ConfigCatLogger implements IConfigCatLogger {
    constructor(private logger: Logger) {}

    debug(message: string): void {
        this.logger.debug(message);
    }

    log(message: string): void {
        this.logger.debug(message);
    }

    info(message: string): void {
        this.logger.debug(message);
    }

    warn(message: string): void {
        this.logger.warn(message);
    }

    error(message: string): void {
        this.logger.error(message);
    }
}

export function configFetcherAdaptor({
    fetchFeatureFlagConfig,
    productName,
    configName,
}: {
    fetchFeatureFlagConfig: () => Promise<{ featureFlagConfig: FeatureFlagConfig; etag: string }>;
    productName: string;
    configName: string;
}): IConfigFetcher {
    return {
        fetchLogic(options, lastEtag, callback) {
            fetchFeatureFlagConfig().then(
                ({ featureFlagConfig, etag }) => {
                    const config = featureFlagConfig.configs.find((item) => item.productName === productName && item.configName === configName);
                    if (!config) {
                        options.logger.error(`No feature flag config found for product ${productName} and config ${configName}`);
                        callback(FetchResult.error());
                        return;
                    }
                    callback(FetchResult.success(config.values, etag));
                },
                () => {
                    callback(FetchResult.error());
                }
            );
        },
    };
}

export function createConfigCatUpdater({ logger }: { logger: Logger }): FeatureFlagsUpdater {
    const configCatLogger = new ConfigCatLogger(logger);
    return {
        listenForChanges({ configName, productName, userData, onChange }) {
            const configCatUser = getConfigCatUser(userData);
            const updateValues: typeof onChange = (values) => {
                logger.debug('Got ConfigCat Values', { configCatUser, values });
                onChange(values);
            };
            const client = createClientWithAutoPoll(
                'random',
                {
                    configFetcher: configFetcherAdaptor({ configName, productName, fetchFeatureFlagConfig: fetchFeatureFlagsFromCacheWithEtag }),
                    sdkType: 'random',
                    sdkVersion: 'random',
                },
                {
                    pollIntervalSeconds: 300,
                    configChanged: () => {
                        client.getAllValues(updateValues, configCatUser);
                    },
                    logger: configCatLogger,
                }
            );
        },

        async getInitialValues({ configName, productName, userData, featureFlagConfig }) {
            const client = createClientWithAutoPoll(
                'random',
                {
                    configFetcher: configFetcherAdaptor({ configName, productName, fetchFeatureFlagConfig: async () => ({ featureFlagConfig, etag: Date.now().toString() }) }),
                    sdkType: 'random',
                    sdkVersion: 'random',
                },
                {
                    logger: configCatLogger,
                }
            );

            try {
                return await client.getAllValuesAsync(getConfigCatUser(userData));
            } finally {
                client.dispose();
            }
        },
    };
}
