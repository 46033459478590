"use strict";
/* eslint-disable prettier/prettier */
Object.defineProperty(exports, "__esModule", { value: true });
exports.HeadTags = void 0;
var HeadTags;
(function (HeadTags) {
    HeadTags["TITLE"] = "title";
    HeadTags["BASE"] = "base";
    HeadTags["LINK"] = "link";
    HeadTags["STYLE"] = "style";
    HeadTags["META"] = "meta";
    HeadTags["SCRIPT"] = "script";
})(HeadTags = exports.HeadTags || (exports.HeadTags = {}));
// There may be a better way to handle these typings using something along those lines: type HeadTags = Pick<HTMLElementTagNameMap,  AllowedHeadTags>
// To be explored maybe
