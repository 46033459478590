import { getUserData, UserBffData, UserData } from '@combinativ/user-data';
import { v4 as uuid } from 'uuid';
import { Logger } from '../common/logger';

const devIdCookieName = 'devID';
const guestIdCookieName = 'KGID';

function getCookieValue(cookieName: string) {
    const cookieValue = document.cookie
        .split('; ')
        .find((row) => row.startsWith(`${cookieName}=`))
        ?.split('=')[1];

    return cookieValue ? decodeURIComponent(cookieValue) : cookieValue;
}

function setCookie(cookieName: string, cookieValue: string) {
    document.cookie = `${cookieName}=${encodeURIComponent(cookieValue)}`;
}

function getGuestID() {
    const guestIDFromCookie = getCookieValue(guestIdCookieName);
    if (guestIDFromCookie) {
        return guestIDFromCookie;
    }

    const guestID = uuid();
    setCookie(guestIdCookieName, guestID);
    return guestID;
}

function getDevID() {
    return getCookieValue(devIdCookieName) || undefined;
}

async function fetchUserBffData(logger: Logger): Promise<UserBffData> {
    try {
        const response = await fetch('https://www.manyvids.com/bff/user/data', { credentials: 'include' });
        return await response.json();
    } catch (error) {
        logger.error('Could not fetch user data', { error });
        return { isLoggedIn: false };
    }
}

export async function fetchUserData(logger: Logger): Promise<UserData> {
    const userBffData = await fetchUserBffData(logger);

    return getUserData({
        guestID: getGuestID(),
        devID: getDevID(),
        userData: userBffData,
    });
}
