import { IdentificationInfo, UserLocation } from '@combinativ/client-api';
import { Platform, setupRenderClient } from '../common';
import { fetchFeatureFlagsFromCache } from '../common/feature-flags';
import { loggerFactory } from '../common/logger';
import { loadRootConfig } from './config-loader';
import { handleError } from './errors';
import { setupImportMap } from './import-map';
import { renderMFE, RenderMFEFunction } from './mfe-renderer';
import { triggerReadyState } from './ready';
import './system-resolve';
import { fetchUserData } from './user-data';

type InitInfo = { distributionChannel: string; userLocation?: UserLocation };
type InitFunction = (info: InitInfo) => Promise<void>;

const rootIdentificationInfo: IdentificationInfo = {
    name: 'combinativ-client',
    squad: 'platform',
};

const windowObject = window as unknown as {
    combinativjs: { _q?: Parameters<RenderMFEFunction>[]; _i?: InitInfo; init?: InitFunction; renderMFE?: RenderMFEFunction } | undefined;
};
const globalObject = windowObject.combinativjs || (windowObject.combinativjs = {});

const init: InitFunction = async ({ distributionChannel, userLocation }: InitInfo) => {
    const logger = loggerFactory(rootIdentificationInfo);

    globalObject.init = async () => {
        // eslint-disable-next-line no-console
        console.warn('combinativjs.init has already been called');
    };

    try {
        const [rootConfig, featureFlagConfig, userData] = await Promise.all([loadRootConfig(distributionChannel), fetchFeatureFlagsFromCache(), fetchUserData(logger)]);
        setupImportMap(rootConfig);
        return await setupRenderClient({
            logger,
            platform: Platform.Legacy,
            rootConfig,
            featureFlagConfig,
            userLocation: userLocation ?? { country: undefined, subdivision: undefined },
            userData,
            bootstrap: (services) => {
                services.logger.debug('setup renderer-client bootstrap');
                triggerReadyState(services);
            },
        });
    } catch (error) {
        return handleError(error);
    }
};

/**
 * Perform the initial setup of Combinativ.js client. This is called when the page load.
 */
export async function bootstrap() {
    // setup the real global function
    globalObject.init = init;
    globalObject.renderMFE = renderMFE;

    // process queued render requests
    const queuedRenders = globalObject?._q;
    if (queuedRenders) {
        queuedRenders.forEach(([mfeName, element, props]) => {
            renderMFE(mfeName, element, props);
        });
    }

    // processed saved init info
    const presetInitInfo = globalObject?._i;
    if (presetInitInfo) {
        await init(presetInitInfo);
    }
}
